import React, { useMemo } from 'react';
import EmployeeAvailableDays from './EmployeeAvailableDays';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		marginBottom: theme.spacing(3)
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	}
});

const AvailableDayOff = ({
	classes,
	availableDays = [],
}) => {
	const compensationDays = useMemo(() =>
		availableDays.filter(day => day.type === 'compensation' && day.amount > 0 && !day.expired_at),
	[availableDays]);

	if (compensationDays.length === 0) {
		return null;
	}

	return (
		<div className={classes.root}>
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>Available days</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div>
						{compensationDays.map((dayOff, i) => (
							<EmployeeAvailableDays key={`${dayOff.type}-${i}`} dayOff={dayOff} />
						))}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default withStyles(styles)(AvailableDayOff);
