// This file wraps all the URL that React use to consume the API

// Base
export const BASE_URL_USER_PROFILE = '/api/user/profile';
export const BASE_URL_USERS = '/api/users/';
export const BASE_URL_ROLES = '/api/role/';
export const BASE_URL_PERMISSIONS = '/api/permission';
export const BASE_URL_EMPLOYEES = '/api/employees/';
export const BASE_URL_DAYS_CLAIM = '/api/days_claim/';
export const BASE_URL_DAYS_OFF = '/api/days_off/';
export const BASE_URL_BADGES = '/api/badges/';
export const BASE_URL_QUARTERS = '/api/quarters/';
export const BASE_URL_BADGE_ASSIGNMENTS = '/api/badge_assignments/';
export const BASE_URL_LEVEL_ASSIGNMENTS = '/api/level_assignments/';
export const BASE_URL_ACTIONS = '/api/actions/';
export const BASE_URL_PADAWAN_JOURNEYS = '/api/padawan/journeys/';
export const BASE_URL_PADAWAN_TOC = '/api/padawan/toc/';
export const BASE_URL_PAYSLIPS = '/api/payslips/';

// Special
export const MANAGER_EMPLOYEES = `/api/manager_employees`;
