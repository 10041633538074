import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
	title: {
		margin: '5px 0 7px 0'
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		'font-size': '1em',
		background: 'white',
		color: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.secondary.main}`
	},
	days: {
		margin: '1px'
	},
});

const ClaimedDays = (props) => {
	const { classes, claim } = props;
	const { amount, _id	} = claim;

	return (
		<div>
			<h4 className={classes.title}>General timespan info</h4>

			<Chip
				key={`total_days${_id}`}
				className={classes.days}
				icon={<Avatar className={classes.small}>{amount}</Avatar>}
				variant="outlined"
				color="secondary"
				label="Days off"
			/>
		</div>
	);
};

export default withStyles(styles)(ClaimedDays);
