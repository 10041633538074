/**
 * Ekumen App
 * Main file, loads the Router and other nice things
 */
import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';

import LoadingComponent from 'components/LoadingComponent';
import AppRouter from './AppRouter';
import AppLogin from './AppLogin';
import { UserContext, EmployeeContext } from './AppContexts';

const theme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			light: '#AE66FF',
			main: '#683C95',
			contrastDefaultColor: 'light'
		},
		secondary: {
			light: '#448FF4',
			main: '#2B65E7',
			contrastDefaultColor: 'dark'
		},
		grayScale: {
			white: '#ffffff',
			lighter: '#999999',
			light: '#666666',
			main: '#333333',
			dark: '#000000'
		},
		badges: {
			core: {
				text: '#a94442',
				background: '#f2dede',
				border: '#ebccd1'
			},
			elective: {
				text: '#31708f',
				background: '#d9edf7',
				border: '#bce8f1'
			},
			oneOff: {
				text: '#3c763d',
				background: '#dff0d8',
				border: '#d6e9c6'
			},
			0: {
				text: '#a94442',
				background: '#f2dede',
				border: '#ebccd1'
			},
			1: {
				text: '#31708f',
				background: '#d9edf7',
				border: '#bce8f1'
			},
			2: {
				text: '#3c763d',
				background: '#dff0d8',
				border: '#d6e9c6'
			}
		}
	},
	typography: {
		fontFamily: "Inter, Arial, sans-serif",
	},
	fontWeight: {
		bold: 750,
		medium: 550,
		light: 250
	}
});

const updateAxios = () => {
	const token = localStorage.getItem('token');

	if (token) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common['Authorization'];
	}
};

export default class App extends React.PureComponent {

	state = {
		loading: true,
		data: null,
		error: null
	};

	handleLogin = (token) => {
		//Save the token
		localStorage.setItem('token', token);
		this.updateLocalData();
	};

	handleLogout = () => {
		localStorage.removeItem('token');
		this.setState({ user: null });
	};

	updateLocalData = () => {
		this.setState({ loading: true });
		updateAxios();

		//TODO: Preload 'static' models from the server, like Countries.
		//Load authenticated user (if any)
		axios.post('/api/app/user').then((response) => {
				const { data } = response;
				this.setState({
					user: data.user,
					employee: data.employee,
					modules: data.modules,
					error: null
				});
			}).catch((e) => {
				this.setState({ data: null, user: null, modules: [], error: e });
			}).finally(() => {
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		//Load app-wide configuration
		axios.get('/config').then((response) => {
			const { data } = response;

			this.setState({ googleClientId: data.googleClientId }, () => {
				if (localStorage.getItem('token')) {
					this.updateLocalData();
				} else {
					this.setState({ loading: false });
				}
			});
		}).catch((e) => {
			console.error('Error loading app config', e);
			this.setState({ data: null, user: null, modules: [], error: e });
		});
	}

	renderApp() {
		const { user, employee, modules, loading, googleClientId } = this.state;

		if (loading) {
			return <LoadingComponent />;
		}

		if (!user) {
			return <AppLogin onLogin={this.handleLogin}
				googleClientId={googleClientId} />;
		}

		return (
			<UserContext.Provider value={user}>
				<EmployeeContext.Provider value={employee}>
					<AppRouter user={user} modules={modules} onLogout={this.handleLogout} />
				</EmployeeContext.Provider>
			</UserContext.Provider>
		);
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<MuiThemeProvider theme={theme}>
					<Router sensitive>{this.renderApp()}</Router>
				</MuiThemeProvider>
			</React.Fragment>
		);
	}
}
