/**
 * Controls the routing within the app (front end)
 * when the url matches, it renders the passed component
 */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TransitionSnackbar from 'components/TransitionSnackbar';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

const styles = (theme) => ({
	mainContent: {
		display: 'flex',
		alignItems: 'top',
		justifyContent: 'center',
		flexDirection: 'column',
		height: '100vh',
		backgroundImage: 'url(/hex_background.png)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	paper: {
		minWidth: '400px',
		maxWidth: '650px',
		width: '50vw',
		margin: 'auto',
		border: `1px solid ${theme.palette.secondary.dark}`,
		padding: theme.spacing(2)
	},
	title: {
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	text: {
		color: theme.palette.primary.main
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end'
	}
});

class AppLogin extends React.PureComponent {
	state = {
		snackState: 'off',
		snackMessage: ''
	};

	// Success callback of the Google Login Button.
	onGoogleToken = (token) => {
		// Send the obtained credentials to backend.
		// The response is a GoogleUser object.
		// https://developers.google.com/identity/sign-in/web/reference

		// Send the token to the Server.
		axios
			.post('/login', { token })
			.then((res) => {
				// The response should contain a signed token from the Server.
				// Store it in the local storage.
				this.setState({ snackState: 'success', snackMessage: 'Authenticated!' }, () => {
					this.props.onLogin(res.data /*the token!*/);
				});
			})
			.catch((e) => {
				this.setState({ snackState: 'error', snackMessage: e.toString() });
			});
	};

	// Failure callback of the Google Login Button.
	onGoogleFailure = (response) => {
		this.setState({ snackState: 'error', snackMessage: response.reason });
	};

	closeSnackbar = () => {
		this.setState({ error: null, snackState: 'off' });
	};

	render() {
		const { classes, googleClientId } = this.props;
		const { snackMessage, snackState } = this.state;

		return (
			<div className={classes.mainContent}>
				<GoogleOAuthProvider clientId={googleClientId} >
				<Paper className={classes.paper}>
					<Typography
						variant="h2"
						component="h2"
						gutterBottom
						align="center"
						className={classes.title}
					>
						Ekumen
					</Typography>

					<Typography
						variant="h5"
						component="h2"
						gutterBottom
						align="center"
						className={classes.text}
					>
						- Employees only -
					</Typography>

					<div className={classes.buttons}>
							<GoogleLogin
							useOneTap={true}
							hosted_domain="creativa77.com.ar"
							scope="profile email"
  							onSuccess={credentialResponse => {
								this.onGoogleToken(credentialResponse.credential);
  							}}
  							onError={this.onGoogleFailure} />
					</div>
				</Paper>
				<TransitionSnackbar
					onClose={this.closeSnackbar}
					state={snackState}
					message={snackMessage}
				/>
				</GoogleOAuthProvider>
			</div>
		);
	}
}

AppLogin.propTypes = {
	classes: PropTypes.object.isRequired,
	onLogin: PropTypes.func.isRequired,
	googleClientId: PropTypes.string.isRequired,
};

export default withStyles(styles)(AppLogin);
